<template>
  <div class="content">
    <breadcrumb></breadcrumb>
    <div style="display: flex" v-loading="loading">
      <el-card :body-style="{ padding: '0 30px', minHeight: '450px' }">
        <div slot="header" class="clearfix" style="text-align: center">
          <el-button
            type="text"
            @click="getList({ cate_id: '' })"
            style="color: #000"
            ><h2>{{ downTitle }}</h2></el-button
          >
        </div>
        <div class="el-button-list">
          <el-button
            type="text"
            :class="where.cate_id == cate.value ? 'is-active' : ''"
            @click="getList({ cate_id: cate.value })"
            v-for="(cate, cateKey) in cateList"
            :key="cateKey"
            >{{ cate.title }}</el-button
          >
        </div>
      </el-card>
      <el-card
        style="margin-left: 20px; flex: 1"
        :body-style="{ padding: '20px 0', height: 'calc(100% - 40px)' }"
      >
        <!-- <com-table style="height: 100%;" :where="where" ref="table" :columns="[]" :parseMethod="parseMethod" :data="{url:'/xapi/down/list'}" list-type="list"> -->
        <com-table
          style="height: 100%"
          :where="where"
          ref="table"
          :columns="[]"
          :parseMethod="parseMethod"
          :data="{ url: '/xapi/user.down/list' }"
          list-type="list"
        >
          <template slot-scope="{ row }">
            <div style="display: flex; padding: 0 20px">
              <el-image
                style="width: 200px; height: 100px; border-radius: 4px"
                size="100"
                fit="fill"
                :src="row.cover"
              ></el-image>
              <div style="flex: 1; padding: 20px">
                <div class="shenluehao">{{ row.name }}</div>
                <div
                  class="shenluehao"
                  style="margin-top: 20px"
                  v-html="row.desc"
                ></div>
              </div>
              <div style="padding: 30px 0">
                <!-- <el-button type="primary" icon="el-icon-download" @click="$tool.fileResponse(row.file_id||row.url)"> 下载</el-button> -->
                <el-button
                  type="primary"
                  icon="el-icon-download"
                  @click="openDownTable(row)"
                >
                  下载</el-button
                >
              </div>
            </div>
          </template>
        </com-table>
      </el-card>
    </div>

    <el-dialog title="文件下载" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column
          property="original"
          label="文件名"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          property="bak"
          label="备注标签"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.bak || "--" }}
          </template>
        </el-table-column>

        <el-table-column property="e_url" align="center" label="安装说明">
          <template slot-scope="scope">
            <el-button
            v-if="scope.row.e_url"
              @click="$tool.fileResponse(scope.row.e_url)"
              type="text"
              size="small"
              >下载安装说明</el-button
            >
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column property="url" align="center" label="下载地址">
          <template slot-scope="scope">
            <el-button
              @click="$tool.fileResponse(scope.row.file_id || row.url)"
              type="text"
              size="small"
              >下载文件</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      where: {},
      cateList: [],
      downTitle: "",
      downList: [],
      dialogTableVisible: false,
      gridData: [],
    };
  },
  methods: {
    routeUpdate() {
      this.getCateList();
    },
    getList(where) {
      if (where) {
        this.where = Object.assign({}, this.where, where);
      }
      this.$refs.table.reset(this.where);
    },
    // 打开下载弹框
    openDownTable(row) {
      this.dialogTableVisible = true;
      this.gridData = row.url_group;
    },
    parseMethod(res, callback) {
      this.downTitle = (res.data || {}).title || "软件下载";
      callback(
        [...((res.data || {}).data || [])],
        (res.data || {}).total || (res.data || {}).count || 0
      );
    },
    getCateList() {
      this.loading = true;
      // this.$http.post('/xapi/down/cateList' ,{}).then(({data})=>{
      this.$http
        .post("/xapi/user.down/cateList", {})
        .then(({ data }) => {
          this.cateList = (data || {})._list || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep {
  .el-card__header {
    border: 0;
  }
  .el-button-list .el-button {
    display: block;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .el-button-list .el-button.el-button--text:not(.is-active) {
    color: #606266 !important;
  }
  .el-button-list .el-button.el-button--text.is-active {
    background-color: #cfebe9;
    border: none;
    border-radius: 0;
  }
  .el-avatar > img {
    width: 100%;
  }
}
</style>